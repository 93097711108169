import React from 'react';
import styled from 'styled-components';
import {useI18next, Trans, Helmet} from 'gatsby-plugin-react-i18next';
import {styles, media, links} from 'global.config';
import Button from 'components/Button';
import Stars from 'components/Stars';
import TopSectionImage from 'images/email-marketing-software-api-tinker-rocket.svg';
import Cylon from 'images/email-marketing-software-api-cylon.svg';
import Earth from 'images/email-marketing-software-api-earth.svg';
import Rocket from 'images/email-marketing-software-api-rocket.svg';
import TemplateRocket from 'images/email-marketing-software-api-template-rocket.svg';
import SpaceBox from 'images/email-marketing-software-api-space-box@2x.png';
import AsSeenOn from 'components/Alternatives/AsSeenOn';

const IndexSection = styled.section`
  text-align: center;
  color: #fff;
  position: relative;
`;

const IndexSectionContainer = styled.div`
  padding: 135px ${styles.containerPadding} 80px;
  position: relative;
  min-height: calc(100vh - 170px);
  margin: auto;
  max-width: ${styles.containerWidth};
  @media ${media.mobile} {
    padding-top: 150px;
  }
`;

const IndexSectionImage = styled.img`
  display: block;
  margin: 0 auto;
  @media ${media.mobile} {
    width: 235px;
    margin-bottom: 20px;
  }
`;

const IndexSectionSummary = styled.p`
  font-size: 16px;
  line-height: 20px;
  max-width: initial;
  margin: 30px auto;
  font-weight: 300;
`;

const BoxesContainer = styled.div`
  p {
    margin: 0 0 10px;
  }
  text-align: center;
  max-width: 760px;
  margin: 60px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`;
const BoxHeading = styled.p`
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 15px;
`;

const Box = styled.div`
  background: url(${SpaceBox}) no-repeat 50% 50%;
  height: 305px;
  width: 370px;
  font-size: 13px;
  font-weight: 500;
  line-height: 1.23;
  text-align: center;
  color: #ffffff;
  vertical-align: top;
  border-radius: 7px;
  margin-bottom: 18px;
  .soon {
    font-size: 16px;
    padding-top: 20px;
  }
  &:nth-child(odd) {
    margin-right: 18px;
  }
  &:after {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    content: '';
  }
  @media ${media.mobile} {
    margin-right: 0 !important;
  }
`;

const BoxImage = styled.img`
  margin-bottom: 3px;
`;

const Content = styled.div`
  display: inline-block;
  vertical-align: middle;
  max-width: 270px;
`;

const Page: React.FC<{}> = ({}) => {
  const {t} = useI18next();
  return (
    <>
      <Helmet title={t('Email Marketing Software API')}>
        <meta
          name="description"
          content={t(
            "Get up and running with the MoonMail's API. Add contacts, create campaigns and automations programatically from third parties. Click here to learn more!"
          )}
        />
      </Helmet>
      <div>
        <Stars>
          <IndexSection>
            <IndexSectionContainer>
              <IndexSectionImage
                src={TopSectionImage}
                alt={t('api email marketing')}
                title={t('api email marketing')}
                width={303}
                height={201}
              />
              <h1>{t('Fancy Some Tinkering?')}</h1>
              <IndexSectionSummary>
                <Trans>
                  MoonMail GraphQL API is now available for those who know what that is.
                </Trans>
              </IndexSectionSummary>
              <p>
                <Button href={links.api} title={t("use MoonMail's API")}>
                  {t('Start tinkering')}
                </Button>
              </p>
            </IndexSectionContainer>
          </IndexSection>
          <AsSeenOn />
        </Stars>
        <BoxesContainer>
          <Box>
            <Content>
              <BoxImage
                src={Earth}
                alt={t('MoonMail Contacts API')}
                title={t('MoonMail Contacts API')}
                width="59"
                height="59"
              />
              <BoxHeading>
                <Trans>Contacts API</Trans>
              </BoxHeading>
              <p>
                <Trans>
                  Easily inject, repair and burn off contacts and metafields from your space
                </Trans>
              </p>
              <Button href={links.api} target="_blank">
                Play now
              </Button>
            </Content>
          </Box>
          <Box>
            <Content>
              <BoxImage
                src={Rocket}
                alt={t('MoonMail campaigns API')}
                title={t('MoonMail campaigns API')}
                width="65"
                height="65"
              />
              <BoxHeading>
                <Trans>Campaigns API</Trans>
              </BoxHeading>
              <p>
                <Trans>Create and Schedule any type of Campaign</Trans>
              </p>
              <Button href={links.api} target="_blank">
                Play now
              </Button>
            </Content>
          </Box>
          <Box>
            <Content>
              <BoxImage
                src={Cylon}
                alt={t('MoonMail automation API')}
                title={t('MoonMail automation API')}
                width="65"
                height="65"
              />
              <BoxHeading>
                <Trans>Segments API</Trans>
              </BoxHeading>
              <p>
                <Trans>Create and manage your Segments of Contacts</Trans>
              </p>
              <Button href={links.api} target="_blank">
                Play now
              </Button>
            </Content>
          </Box>
          <Box>
            <Content>
              <BoxImage
                src={TemplateRocket}
                alt={t('MoonMail templates API')}
                title={t('MoonMail templates API')}
                width="65"
                height="65"
              />
              <BoxHeading>
                <Trans>Events API</Trans>
              </BoxHeading>
              <p>
                <Trans>Send targeted campaigns based on events</Trans>
              </p>
              <Button href={links.api} target="_blank">
                Play now
              </Button>
            </Content>
          </Box>

          <Box>
            <Content>
              <BoxImage
                src={Earth}
                alt={t('MoonMail Contacts API')}
                title={t('MoonMail Contacts API')}
                width="65"
                height="65"
              />
              <BoxHeading>
                <Trans>Templates API</Trans>
              </BoxHeading>
              <p>
                <Trans>Create and manage your Templates programatically</Trans>
              </p>
            </Content>
          </Box>
          <Box>
            <Content>
              <BoxImage
                src={Earth}
                alt={t('MoonMail Contacts API')}
                title={t('MoonMail Contacts API')}
                width="65"
                height="65"
              />
              <BoxHeading>
                <Trans>Journeys API</Trans>
              </BoxHeading>
              <p>
                <Trans>
                  Create complex and dynamic messaging journeys based on your contacts behaviour
                </Trans>
              </p>
            </Content>
          </Box>
          <Box>
            <Content>
              <BoxImage
                src={Earth}
                alt={t('MoonMail Contacts API')}
                title={t('MoonMail Contacts API')}
                width="65"
                height="65"
              />
              <BoxHeading>
                <Trans>Channels API</Trans>
              </BoxHeading>
              <p>
                <Trans>
                  Send your campaigns to multiple Channels like: SMS, Voice, Email, Push
                  notifications (Firebase Cloud Messaging, Apple Push Notification service, Baidu
                  Cloud Push, Amazon Device Messaging) and more
                </Trans>
              </p>
            </Content>
          </Box>
          <Box>
            <Content>
              <BoxImage
                src={Earth}
                alt={t('MoonMail Contacts API')}
                title={t('MoonMail Contacts API')}
                width="65"
                height="65"
              />
              <BoxHeading>
                <Trans>Accounts API</Trans>
              </BoxHeading>
              <p>
                <Trans>
                  Provision MoonMail accounts to thousands of users offering a pure white label
                  OmniChannel Communication Platform
                </Trans>
              </p>
            </Content>
          </Box>
        </BoxesContainer>
      </div>
    </>
  );
};

export default Page;
